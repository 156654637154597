<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">
      <back-button/>
      <h5 class="pageTitle">Community Rules</h5>
    </div>
    <hr>


    <div class="body">
      <p>
        Our Community Standards apply to everyone, all around the world, and to all types of content. We want everyone to feel welcome on Goblin Bank, and we have created these rules to define the values of the community we want to build.
      </p>
      <div>
        Here are a few of the things that aren't allowed on Goblin Bank:
        <ul>
          <li>Nudity or other sexually suggestive content</li>
          <li>Hate speech, credible threats or direct attacks on an individual or group</li>
          <li>Content that contains self-harm or excessive violence</li>
          <li>Fake or impostor profiles</li>
          <li>Spam</li>
        </ul>
      </div>
      <p>Goblin Bank reserve the rights to remove any inappropriate contents.</p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'CommunityRules',
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {
    document.title = "Community Rules";
  }
}
</script>

<style scoped>
.body {
  margin: 20px 10px 10px 10px;
  padding: 10px;
  font-size: 13px;
}

.body ul{
  margin-left:20px;
  list-style: unset;
}



</style>